<template>
  <vue-final-modal class="modal-bonus-cancel-lock">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'title') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('bonus-cancel-lock')" />
      </div>
    </div>

    <div class="vfm__body">
      <atomic-icon id="big-decline" class="modal-bonus-cancel-lock__icon" filled />

      <div class="modal-bonus-cancel-lock__subtitle">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'subtitle') }}
      </div>

      <div class="modal-bonus-cancel-lock__description">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'description') }}
      </div>

      <button-base class="modal-bonus-cancel-lock__action" type="secondary-1" size="md" @click="clickConfirm">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'confirmButton') }}
      </button-base>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIModalsContent } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<CIModalsContent['bonusCancelLock']>;
    defaultLocaleData: Maybe<CIModalsContent['bonusCancelLock']>;
  }>();

  const { getContent } = useProjectMethods();
  const { closeModal } = useModalStore();
  const clickConfirm = async (): Promise<void> => {
    window.zE('webWidget', 'show');
    await nextTick();
    window.zE('webWidget', 'open');
    await closeModal('bonus-cancel-lock');
  };
</script>

<style src="~/assets/styles/components/modal/bonus-cancel-lock.scss" lang="scss" />
